<template>
  <div id="app">
    <van-nav-bar title="计划维修" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon v-if="finishWx" name="qr" size="20" @click="searchWorkOrder" />
      </template>
    </van-nav-bar>
    <van-tabs v-model:active="active" :ellipsis="false" @click="clickTabs">
      <van-tab name="total">
        <template #title>全部 ({{ totalPm }})</template>
      </van-tab>
      <van-tab name="unTodo">
        <template #title>待派发 ({{ unTodoPm }})</template>
      </van-tab>
      <van-tab name="todo">
        <template #title>待维修 ({{ todoPm }})</template>
      </van-tab>
      <van-tab name="toMonitor">
        <template #title>待班长审核 ({{ toMonitorPm }})</template>
      </van-tab>
      <van-tab name="toEngineer">
        <template #title>待工程师审核 ({{ toEngineerPm }})</template>
      </van-tab>
      <van-tab name="done">
        <template #title>完成 ({{ donePm }})</template>
      </van-tab>
      <van-tab name="timeout">
        <template #title>超时 ({{ timeoutPm }})</template>
      </van-tab>
      <van-tab name="timeoutTodo">
        <template #title>超时待维修 ({{ timeoutTodoPm }})</template>
      </van-tab>
      <van-tab name="timeoutToMonitor">
        <template #title>超时待班长审核 ({{ timeoutToMonitorPm }})</template>
      </van-tab>
      <van-tab name="timeoutToEngineer">
        <template #title>超时待工程师审核 ({{ timeoutToEngineerPm }})</template>
      </van-tab>
      <van-tab name="timeoutDone">
        <template #title>超时完成 ({{ timeoutDonePm }})</template>
      </van-tab>
    </van-tabs>
    <!--    <van-search hidden v-model="mobileSearch" placeholder="请输入搜索关键词" @search="onSearch" class="asset-search" />-->
    <div class="common-list" style="margin-top: 10px;">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMore" offset="1" :immediate-check="false">
          <van-cell v-for="(item, index) in pmList" :key="index">
            <div class="cell-title" @click="goUrls(item.id)">
              <p class="title-left">
                <span v-html="statusFormat(item)" />
                <span v-if="item.eq">
                  <span>{{ item.eq.name }}</span>
                  <span style="display: block;">
                    <span>执行者：{{item.other_member.map(i => {return i.admin.name}).join('，')}}</span>
                  </span>
                  <span style="display: block;">
                    <span>设备编号：{{ item.eq.coding }}</span>
                  </span>
                  <span v-if="item.place" style="display: block;">
                    <span>设备所在地：{{ item.place }}</span>
                  </span>
                  <span v-if="item.workshop" style="display: block;">
                    <span>承修部门：{{ item.workshop }}</span>
                  </span>
                  <span style="display: block;">
                    <span>安排时间：{{ item.arrange_date }}</span>
                  </span>
                </span>
                <span v-else>该设备已被删除</span>
              </p>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import {Notify, Toast} from 'vant'
import API from '@/api/workorder/pm'
import wx from "weixin-js-sdk"
import SELECT from "@/api/select"
import WxApi from "@/api/wx";
export default {
  inject: ['goUrl', 'getPmStatus'],
  data() {
    return {
      active: 0,
      query: { page: 0, limit: 15, plan_category: 9 },
      mobileSearch: '',
      pmList: [],
      loading: false,
      finished: false,
      refreshing: false,
      finishLoading: false,
      finishWx: false,
      totalPm: 0,
      unTodoPm: 0,
      todoPm: 0,
      donePm: 0,
      timeoutPm: 0,
      timeoutTodoPm: 0,
      toMonitorPm: 0,
      toEngineerPm: 0,
      timeoutToMonitorPm: 0,
      timeoutToEngineerPm: 0,
      timeoutDonePm: 0,
      qrPm: 0,
      eqId: null //扫码后得到的id，url中携带这个id才可以直接打开detail页的选项卡
    }
  },
  created() {
    this.getConfig()
    this.index()
  },
  methods: {
    index() {
      this.finishLoading = false
      this.query.page += 1
      if (this.refreshing) {
        this.pmList = []
        this.refreshing = false
      }
      return API.pmList(this.query).then(res=> {
        this.totalPm = res.data.m_total
        this.unTodoPm = res.data.un_todo
        this.todoPm = res.data.todo
        this.donePm = res.data.done
        this.timeoutPm = res.data.timeout
        this.timeoutTodoPm = res.data.timeout_todo
        this.donePm = res.data.done
        this.toMonitorPm = res.data.to_monitor
        this.toEngineerPm = res.data.to_engineer
        this.timeoutToMonitorPm = res.data.timeout_to_monitor
        this.timeoutToEngineerPm = res.data.timeout_to_engineer
        this.timeoutDonePm = res.data.timeout_done
        if (res.data.error_code === 888) {
          Toast('暂无工单')
          this.pmList = []
        } else {
          this.pmList.push(...res.data.data.data)
          this.loading = false
          if (this.active === 'unTodo') {
            this.pmList.length >= this.unTodoPm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 'todo') {
            this.pmList.length >= this.todoPm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 'toMonitor') {
            this.pmList.length >= this.toMonitorPm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 'toEngineer') {
            this.pmList.length >= this.toEngineerPm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 'done') {
            this.pmList.length >= this.donePm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 'timeout') {
            this.pmList.length >= this.timeoutPm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 'timeoutTodo') {
            this.pmList.length >= this.timeoutTodoPm ? (this.finished = true) : (this.finished = false)
          }  else if (this.active === 'timeoutToMonitor') {
            this.pmList.length >= this.timeoutToMonitorPm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 'timeoutToEngineer') {
            this.pmList.length >= this.timeoutToEngineerPm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 'timeoutDone') {
            this.pmList.length >= this.timeoutDonePm ? (this.finished = true) : (this.finished = false)
          } else {
            // 全部
            this.pmList.length >= this.totalPm ? (this.finished = true) : (this.finished = false)
          }
        }
      }).finally(() => {
        this.finishLoading = true
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onSearch() {
      this.query.mobileSearch = this.mobileSearch
      this.query.page = 0
      this.eqList = []
      this.index()
    },
    onRefresh() {
      this.pmList = []
      this.query.page = 0
      this.finished = true
      this.index()
    },
    loadMore() {
      if (this.finishLoading === true) {
        this.index()
      }
    },
    goUrls(id) {
      const url = `/workorder/pm9_detail/${id}`
      if (!this.eqId) {
        this.goUrl(url)
      } else {
        this.$router.push({
          path: url,
          query: {eqId: this.eqId}
        })
      }
    },
    clickTabs(name) {
      this.pmList = []
      this.query.page = 0
      this.finished = true

      // 待派发 待维修 待班长 待工程师 完成 超时
      if (name === 'unTodo') {
        this.query.status = -1
      } else if (name === 'todo') {
        this.query.status = 1
      } else if (name === 'toMonitor') {
        this.query.status = 20
      } else if (name === 'toEngineer') {
        this.query.status = 30
      } else if (name === 'done') {
        this.query.status = 2
      } else if (name === 'timeout') {
        this.query.status = 10
      } else if (name === 'timeoutTodo') {
        this.query.status = 100
      } else if (name === 'timeoutToMonitor') {
        this.query.status = 2000
      } else if (name === 'timeoutToEngineer') {
        this.query.status = 3000
      } else if (name === 'timeoutDone') {
        this.query.status = 200
      } else {
        this.query.status = ''
      }
      this.index()
    },
    statusFormat(row) {
      return this.getPmStatus(row)
    },
    selectWorkorderByQrCode(coding) {
      SELECT.eqList({coding}).then(res => {
        if (res.data.total !== 1) {
          Notify({ type: 'danger', message: '二维码无效' })
        } else {
          const eqId = res.data.data[0].id
          this.eqId = eqId

          this.query.equipment_id = eqId
          this.query.page = 0
          this.pmList = []
          this.index()
        }
      })
    },
    searchWorkOrder() {
      sessionStorage.removeItem('qrEq1')
      const self = this
      this.pmIsLoading = true

      this.checkJsApi("scanQRCode").then((rel) => {
        if (rel) {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              const code = res.resultStr
              const coding = code.split('|')
              if (coding.length !== 2) {
                alert('此二维码格式不对，请联系管理员！！')
              }
              if (coding[0] === 'e') {
                // 设备
                self.selectWorkorderByQrCode(coding[1])
              } else {
                alert('这不是设备的二维码')
              }
            }
          })
        }
      })
    },
    getConfig() {
      const self = this
      let url = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.localStorage.getItem('scanUrl');

      WxApi.wxJSTicket({ url: url }).then((res) => {
        const data = res.data;
        wx.config({
          beta: true,
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: ['chooseImage','uploadImage','getLocalImgData','scanQRCode','checkJsApi'] // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          self.finishWx = true
        })
        wx.error(function (err) {
          console.log(err)
        })
      })
    },
    checkJsApi(api) {
      //检测api是否可用
      return new Promise((rel, err) => {
        wx.checkJsApi({
          jsApiList: [api], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            if (res.checkResult && res.checkResult.chooseImage == false) {
              this.$toast("微信版本较低，请升级后重试！")
              err("版本低")
            } else {
              rel(true)
            }
          }
        })
      })
    },
  }
}
</script>
<style>
.asset-search{
  background-color: #ebeef5;
}
.status0{
  color: blue;
}
.status0 span{
  background-color: blue !important;
}
.status1{
  color: orange;
}
.status1 span{
  background-color: orange !important;
}
.status2{
  color: green;
}
.status2 span{
  background-color: green !important;
}
</style>
